import React from "react";
import type { HeadFC } from "gatsby";
import { Krk } from "../../screens/OverviewScreen";

const KrkBrandPage = () => {
    return <Krk />;
};

export default KrkBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
